<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
    <section>
      <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/videos%2Fbillboard.mp4?alt=media&token=195966dc-2d09-43a9-a273-7a363d5ba387" type="video/mp4">
        <source src="movie.ogg" type="video/ogg">
        Your browser does not support the video tag.
      </video>
      <div class="overlay-clear pad-fix">
        <div class="display-3">
          <span class="thin">Advertising</span>
        </div><br />
        <div class="display-4">
          <span class="thick">Opportunities</span>
        </div>
        
        </v-container>
        </div>
      </div>
    </section>


    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Smart Advertising with Interactive Data</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Real-time data collection</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Instant feedback</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Drive traffic and control crowds</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Fun, engaging, and easy-to-use</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Industries',
          disabled: false,
          exact: true,
          to: '/industries'
        },
        {
          text: 'Advertising',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>